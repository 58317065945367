<template>
  <div>
    <CRow>
      <CCol sm="12" md="12">
        <CCard accent-color="success">
          <CCardHeader><b> Users Summary</b></CCardHeader>
            <CCardBody>
                <div class="row">
                    <div class="col-12 col-sm-6 col-lg-3">
                        <router-link to="/withdraws/pending" v-if="$can('withdraw-pending')" style="text-decoration: none;">
                            <div class="card bg-primary">
                                <div class="card-body d-flex align-items-center p-3">
                                    <div class=" text-light text-uppercase font-weight-bold"> Pen. Withdraws ({{ formData.pending_withdraw }}) </div>
                                </div>
                            </div>
                        </router-link>
                    </div>
                    <div class="col-12 col-sm-6 col-lg-3">
                        <router-link to="/withdraws/approve" v-if="$can('withdraw-completed')"  style="text-decoration: none;">
                            <div class="card bg-success">
                                <div class="card-body d-flex align-items-center p-3">
                                    <div class=" text-light text-uppercase font-weight-bold"> Com. Withdraws ({{ formData.com_withdraw }}) </div>
                                </div>
                            </div>
                        </router-link>
                    </div>
                    <div class="col-12 col-sm-6 col-lg-3">
                        <router-link to="/withdraws/reject"  v-if="$can('withdraw-reject')" style="text-decoration: none;">
                        <div class="card bg-info">
                            <div class="card-body d-flex align-items-center p-3">
                                <div class=" text-light text-uppercase font-weight-bold"> Rej. Withdraws ({{ formData.reject_withdraw }}) </div>
                            </div>
                        </div>
                        </router-link>
                    </div>
                    <div class="col-12 col-sm-6 col-lg-3">
                        <router-link to="/withdraws/all"  v-if="$can('all-withdraw')" style="text-decoration: none;">

                        <div class="card bg-danger">
                            <div class="card-body d-flex align-items-center p-3">
                                <div class=" text-light text-uppercase font-weight-bold"> Total Withdraws ({{ formData.total_withdraw }}) </div>
                            </div>
                        </div>
                        </router-link>
                    </div>
                </div>
            </CCardBody>
        </CCard>
      </CCol>
    </CRow>
    <CRow>
      <CCol sm="12" md="12">
        <CCard accent-color="success">
          <CCardHeader><b> Register Summary</b></CCardHeader>
            <CCardBody>
                <div class="row">
                    <div class="col-12 col-sm-6 col-lg-3">
                        <div class="card">
                            <div class="card-body d-flex align-items-center p-0">
                                <div class="mr-3 text-white bg-gradient-primary p-4">
                                    <svg height="40px" width="40px" version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" viewBox="0 0 489.7 489.7" xml:space="preserve">
                                        <g><g>
                                                <circle id="XMLID_1944_" style="fill:#A7A9AC;" cx="244.9" cy="47" r="37.8"/>
                                                <circle id="XMLID_1942_" style="fill:#A7A9AC;" cx="417.3" cy="343.3" r="37.8"/>
                                                <circle id="XMLID_1940_" style="fill:#A7A9AC;" cx="72.5" cy="343.3" r="37.8"/>
                                                <path d="M464.2,146.5c0-25.9-21-46.9-46.9-46.9c-14.1,0-26.7,6.2-35.3,16.1L289.3,62c1.6-4.7,2.5-9.8,2.5-15.1
                                                    c0-25.9-21-46.9-46.9-46.9S198,21,198,46.9c0,5.3,0.9,10.3,2.5,15l-92.7,53.7c-8.6-9.8-21.2-16.1-35.3-16.1
                                                    c-25.9,0-46.9,21-46.9,46.9c0,22.4,15.8,41.2,36.8,45.8l0.1,105.2c-21.1,4.6-37,23.4-37,45.8c0,25.9,21,46.9,46.9,46.9
                                                    c13.8,0,26.2-6,34.8-15.5l93,53.7c-1.5,4.6-2.3,9.4-2.3,14.5c0,25.9,21,46.9,46.9,46.9s46.9-21,46.9-46.9c0-5.1-0.8-9.9-2.3-14.5
                                                    l93-53.7c8.6,9.5,21,15.5,34.8,15.5c25.9,0,46.9-21,46.9-46.9c0-22.7-16.3-41.8-37.8-46V192.4
                                                    C447.9,188.3,464.2,169.3,464.2,146.5z M417.3,117.7c15.9,0,28.8,12.9,28.8,28.8s-12.9,28.8-28.8,28.8s-28.8-12.9-28.8-28.8
                                                    S401.5,117.7,417.3,117.7z M244.9,18.2c15.9,0,28.8,12.9,28.8,28.8c0,4.9-1.2,9.5-3.4,13.6c-0.2,0.2-0.3,0.4-0.5,0.7
                                                    c-0.1,0.2-0.2,0.4-0.3,0.6c-5,8.3-14.2,13.9-24.6,13.9c-15.9,0-28.8-12.9-28.8-28.8S229,18.2,244.9,18.2z M43.7,146.5
                                                    c0-15.9,12.9-28.8,28.8-28.8s28.8,12.9,28.8,28.8s-12.9,28.8-28.8,28.8S43.7,162.4,43.7,146.5z M72.5,372.1
                                                    c-15.9,0-28.8-12.9-28.8-28.8s12.9-28.8,28.8-28.8s28.8,12.9,28.8,28.8C101.2,359.2,88.3,372.1,72.5,372.1z M244.9,471.6
                                                    c-15.9,0-28.8-12.9-28.8-28.8S229,414,244.9,414s28.8,12.9,28.8,28.8S260.8,471.6,244.9,471.6z M280.6,412.5
                                                    c-8.6-10.1-21.4-16.6-35.7-16.6s-27.1,6.4-35.7,16.6l-92.6-53.4c1.8-4.9,2.8-10.3,2.8-15.8c0-23.1-16.7-42.3-38.7-46.2l-0.1-104.5
                                                    c22-3.8,38.8-23.1,38.8-46.2c0-5.3-0.9-10.4-2.5-15.1l92.7-53.7c8.6,9.9,21.3,16.1,35.4,16.1c14.1,0,26.7-6.2,35.3-16.1l92.7,53.7
                                                    c-1.6,4.7-2.5,9.8-2.5,15.1c0,22.7,16.3,41.8,37.8,46v104.8c-21.5,4.2-37.8,23.2-37.8,46c0,5.6,1,10.9,2.8,15.8L280.6,412.5z
                                                    M446.1,343.3c0,15.9-12.9,28.8-28.8,28.8s-28.8-12.9-28.8-28.8s12.9-28.8,28.8-28.8S446.1,327.4,446.1,343.3z"/>
                                                <path d="M288.6,281.1v-32.7c5.5-5.3,8.6-12.6,8.6-20.4v-42.9c0-26.3-21.4-47.7-47.7-47.7h-9.2c-26.3,0-47.7,21.4-47.7,47.7V228
                                                    c0,7.8,3.2,15.1,8.6,20.4v32.7c-10.4,5.4-35.4,19.1-58.8,38.3c-3.9,3.2-4.4,8.9-1.2,12.8s8.9,4.4,12.8,1.2
                                                    c25.9-21.3,53.9-35.4,59.3-38c3.8-1.9,6.1-5.6,6.1-9.9v-41.4c0-3-1.5-5.9-4-7.5c-2.9-1.9-4.6-5.1-4.6-8.6v-42.9
                                                    c0-16.3,13.3-29.6,29.6-29.6h9.2c16.3,0,29.6,13.3,29.6,29.6V228c0,3.4-1.7,6.6-4.6,8.6c-2.5,1.7-4,4.5-4,7.5v41.4
                                                    c0,4.2,2.4,8,6.2,9.9c5.4,2.6,33.4,16.8,59.3,38c1.7,1.4,3.7,2.1,5.7,2.1c2.6,0,5.2-1.1,7-3.3c3.2-3.9,2.6-9.6-1.2-12.8
                                                    C324,300.2,299,286.4,288.6,281.1z"/>
                                        </g></g>
                                    </svg>
                                </div>
                                <div>
                                <div class="text-muted text-uppercase font-weight-bold"> Today </div>
                                <div class="text-value text-gradient-primary">{{ parseFloat(formData.today_user).toFixed(2) }}</div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-12 col-sm-6 col-lg-3">
                        <div class="card">
                            <div class="card-body d-flex align-items-center p-0">
                                <div class="mr-3 text-white bg-gradient-info p-4">
                                    <svg height="40px" width="40px" version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" viewBox="0 0 489.7 489.7" xml:space="preserve">
                                        <g><g>
                                                <circle id="XMLID_1944_" style="fill:#A7A9AC;" cx="244.9" cy="47" r="37.8"/>
                                                <circle id="XMLID_1942_" style="fill:#A7A9AC;" cx="417.3" cy="343.3" r="37.8"/>
                                                <circle id="XMLID_1940_" style="fill:#A7A9AC;" cx="72.5" cy="343.3" r="37.8"/>
                                                <path d="M464.2,146.5c0-25.9-21-46.9-46.9-46.9c-14.1,0-26.7,6.2-35.3,16.1L289.3,62c1.6-4.7,2.5-9.8,2.5-15.1
                                                    c0-25.9-21-46.9-46.9-46.9S198,21,198,46.9c0,5.3,0.9,10.3,2.5,15l-92.7,53.7c-8.6-9.8-21.2-16.1-35.3-16.1
                                                    c-25.9,0-46.9,21-46.9,46.9c0,22.4,15.8,41.2,36.8,45.8l0.1,105.2c-21.1,4.6-37,23.4-37,45.8c0,25.9,21,46.9,46.9,46.9
                                                    c13.8,0,26.2-6,34.8-15.5l93,53.7c-1.5,4.6-2.3,9.4-2.3,14.5c0,25.9,21,46.9,46.9,46.9s46.9-21,46.9-46.9c0-5.1-0.8-9.9-2.3-14.5
                                                    l93-53.7c8.6,9.5,21,15.5,34.8,15.5c25.9,0,46.9-21,46.9-46.9c0-22.7-16.3-41.8-37.8-46V192.4
                                                    C447.9,188.3,464.2,169.3,464.2,146.5z M417.3,117.7c15.9,0,28.8,12.9,28.8,28.8s-12.9,28.8-28.8,28.8s-28.8-12.9-28.8-28.8
                                                    S401.5,117.7,417.3,117.7z M244.9,18.2c15.9,0,28.8,12.9,28.8,28.8c0,4.9-1.2,9.5-3.4,13.6c-0.2,0.2-0.3,0.4-0.5,0.7
                                                    c-0.1,0.2-0.2,0.4-0.3,0.6c-5,8.3-14.2,13.9-24.6,13.9c-15.9,0-28.8-12.9-28.8-28.8S229,18.2,244.9,18.2z M43.7,146.5
                                                    c0-15.9,12.9-28.8,28.8-28.8s28.8,12.9,28.8,28.8s-12.9,28.8-28.8,28.8S43.7,162.4,43.7,146.5z M72.5,372.1
                                                    c-15.9,0-28.8-12.9-28.8-28.8s12.9-28.8,28.8-28.8s28.8,12.9,28.8,28.8C101.2,359.2,88.3,372.1,72.5,372.1z M244.9,471.6
                                                    c-15.9,0-28.8-12.9-28.8-28.8S229,414,244.9,414s28.8,12.9,28.8,28.8S260.8,471.6,244.9,471.6z M280.6,412.5
                                                    c-8.6-10.1-21.4-16.6-35.7-16.6s-27.1,6.4-35.7,16.6l-92.6-53.4c1.8-4.9,2.8-10.3,2.8-15.8c0-23.1-16.7-42.3-38.7-46.2l-0.1-104.5
                                                    c22-3.8,38.8-23.1,38.8-46.2c0-5.3-0.9-10.4-2.5-15.1l92.7-53.7c8.6,9.9,21.3,16.1,35.4,16.1c14.1,0,26.7-6.2,35.3-16.1l92.7,53.7
                                                    c-1.6,4.7-2.5,9.8-2.5,15.1c0,22.7,16.3,41.8,37.8,46v104.8c-21.5,4.2-37.8,23.2-37.8,46c0,5.6,1,10.9,2.8,15.8L280.6,412.5z
                                                    M446.1,343.3c0,15.9-12.9,28.8-28.8,28.8s-28.8-12.9-28.8-28.8s12.9-28.8,28.8-28.8S446.1,327.4,446.1,343.3z"/>
                                                <path d="M288.6,281.1v-32.7c5.5-5.3,8.6-12.6,8.6-20.4v-42.9c0-26.3-21.4-47.7-47.7-47.7h-9.2c-26.3,0-47.7,21.4-47.7,47.7V228
                                                    c0,7.8,3.2,15.1,8.6,20.4v32.7c-10.4,5.4-35.4,19.1-58.8,38.3c-3.9,3.2-4.4,8.9-1.2,12.8s8.9,4.4,12.8,1.2
                                                    c25.9-21.3,53.9-35.4,59.3-38c3.8-1.9,6.1-5.6,6.1-9.9v-41.4c0-3-1.5-5.9-4-7.5c-2.9-1.9-4.6-5.1-4.6-8.6v-42.9
                                                    c0-16.3,13.3-29.6,29.6-29.6h9.2c16.3,0,29.6,13.3,29.6,29.6V228c0,3.4-1.7,6.6-4.6,8.6c-2.5,1.7-4,4.5-4,7.5v41.4
                                                    c0,4.2,2.4,8,6.2,9.9c5.4,2.6,33.4,16.8,59.3,38c1.7,1.4,3.7,2.1,5.7,2.1c2.6,0,5.2-1.1,7-3.3c3.2-3.9,2.6-9.6-1.2-12.8
                                                    C324,300.2,299,286.4,288.6,281.1z"/>
                                        </g></g>
                                    </svg>
                                </div>
                                <div>
                                <div class="text-muted text-uppercase font-weight-bold"> PREV. Day </div>
                                <div class="text-value text-gradient-primary">{{ parseFloat(formData.previous_day_user).toFixed(2) }}</div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-12 col-sm-6 col-lg-3">
                        <div class="card">
                            <div class="card-body d-flex align-items-center p-0">
                                <div class="mr-3 text-white bg-gradient-warning p-4">
                                    <svg height="40px" width="40px" version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" viewBox="0 0 489.7 489.7" xml:space="preserve">
                                        <g><g>
                                                <circle id="XMLID_1944_" style="fill:#A7A9AC;" cx="244.9" cy="47" r="37.8"/>
                                                <circle id="XMLID_1942_" style="fill:#A7A9AC;" cx="417.3" cy="343.3" r="37.8"/>
                                                <circle id="XMLID_1940_" style="fill:#A7A9AC;" cx="72.5" cy="343.3" r="37.8"/>
                                                <path d="M464.2,146.5c0-25.9-21-46.9-46.9-46.9c-14.1,0-26.7,6.2-35.3,16.1L289.3,62c1.6-4.7,2.5-9.8,2.5-15.1
                                                    c0-25.9-21-46.9-46.9-46.9S198,21,198,46.9c0,5.3,0.9,10.3,2.5,15l-92.7,53.7c-8.6-9.8-21.2-16.1-35.3-16.1
                                                    c-25.9,0-46.9,21-46.9,46.9c0,22.4,15.8,41.2,36.8,45.8l0.1,105.2c-21.1,4.6-37,23.4-37,45.8c0,25.9,21,46.9,46.9,46.9
                                                    c13.8,0,26.2-6,34.8-15.5l93,53.7c-1.5,4.6-2.3,9.4-2.3,14.5c0,25.9,21,46.9,46.9,46.9s46.9-21,46.9-46.9c0-5.1-0.8-9.9-2.3-14.5
                                                    l93-53.7c8.6,9.5,21,15.5,34.8,15.5c25.9,0,46.9-21,46.9-46.9c0-22.7-16.3-41.8-37.8-46V192.4
                                                    C447.9,188.3,464.2,169.3,464.2,146.5z M417.3,117.7c15.9,0,28.8,12.9,28.8,28.8s-12.9,28.8-28.8,28.8s-28.8-12.9-28.8-28.8
                                                    S401.5,117.7,417.3,117.7z M244.9,18.2c15.9,0,28.8,12.9,28.8,28.8c0,4.9-1.2,9.5-3.4,13.6c-0.2,0.2-0.3,0.4-0.5,0.7
                                                    c-0.1,0.2-0.2,0.4-0.3,0.6c-5,8.3-14.2,13.9-24.6,13.9c-15.9,0-28.8-12.9-28.8-28.8S229,18.2,244.9,18.2z M43.7,146.5
                                                    c0-15.9,12.9-28.8,28.8-28.8s28.8,12.9,28.8,28.8s-12.9,28.8-28.8,28.8S43.7,162.4,43.7,146.5z M72.5,372.1
                                                    c-15.9,0-28.8-12.9-28.8-28.8s12.9-28.8,28.8-28.8s28.8,12.9,28.8,28.8C101.2,359.2,88.3,372.1,72.5,372.1z M244.9,471.6
                                                    c-15.9,0-28.8-12.9-28.8-28.8S229,414,244.9,414s28.8,12.9,28.8,28.8S260.8,471.6,244.9,471.6z M280.6,412.5
                                                    c-8.6-10.1-21.4-16.6-35.7-16.6s-27.1,6.4-35.7,16.6l-92.6-53.4c1.8-4.9,2.8-10.3,2.8-15.8c0-23.1-16.7-42.3-38.7-46.2l-0.1-104.5
                                                    c22-3.8,38.8-23.1,38.8-46.2c0-5.3-0.9-10.4-2.5-15.1l92.7-53.7c8.6,9.9,21.3,16.1,35.4,16.1c14.1,0,26.7-6.2,35.3-16.1l92.7,53.7
                                                    c-1.6,4.7-2.5,9.8-2.5,15.1c0,22.7,16.3,41.8,37.8,46v104.8c-21.5,4.2-37.8,23.2-37.8,46c0,5.6,1,10.9,2.8,15.8L280.6,412.5z
                                                    M446.1,343.3c0,15.9-12.9,28.8-28.8,28.8s-28.8-12.9-28.8-28.8s12.9-28.8,28.8-28.8S446.1,327.4,446.1,343.3z"/>
                                                <path d="M288.6,281.1v-32.7c5.5-5.3,8.6-12.6,8.6-20.4v-42.9c0-26.3-21.4-47.7-47.7-47.7h-9.2c-26.3,0-47.7,21.4-47.7,47.7V228
                                                    c0,7.8,3.2,15.1,8.6,20.4v32.7c-10.4,5.4-35.4,19.1-58.8,38.3c-3.9,3.2-4.4,8.9-1.2,12.8s8.9,4.4,12.8,1.2
                                                    c25.9-21.3,53.9-35.4,59.3-38c3.8-1.9,6.1-5.6,6.1-9.9v-41.4c0-3-1.5-5.9-4-7.5c-2.9-1.9-4.6-5.1-4.6-8.6v-42.9
                                                    c0-16.3,13.3-29.6,29.6-29.6h9.2c16.3,0,29.6,13.3,29.6,29.6V228c0,3.4-1.7,6.6-4.6,8.6c-2.5,1.7-4,4.5-4,7.5v41.4
                                                    c0,4.2,2.4,8,6.2,9.9c5.4,2.6,33.4,16.8,59.3,38c1.7,1.4,3.7,2.1,5.7,2.1c2.6,0,5.2-1.1,7-3.3c3.2-3.9,2.6-9.6-1.2-12.8
                                                    C324,300.2,299,286.4,288.6,281.1z"/>
                                        </g></g>
                                    </svg>
                                </div>
                                <div>
                                <div class="text-muted text-uppercase font-weight-bold"> Last 7 Day </div>
                                <div class="text-value text-gradient-primary">{{ parseFloat(formData.last_seven_day_user).toFixed(2) }}</div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-12 col-sm-6 col-lg-3">
                        <div class="card">
                            <div class="card-body d-flex align-items-center p-0">
                                <div class="mr-3 text-white bg-gradient-danger p-4">
                                    <svg height="40px" width="40px" version="1.1" id="Layer_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" viewBox="0 0 489.7 489.7" xml:space="preserve">
                                        <g><g>
                                                <circle id="XMLID_1944_" style="fill:#A7A9AC;" cx="244.9" cy="47" r="37.8"/>
                                                <circle id="XMLID_1942_" style="fill:#A7A9AC;" cx="417.3" cy="343.3" r="37.8"/>
                                                <circle id="XMLID_1940_" style="fill:#A7A9AC;" cx="72.5" cy="343.3" r="37.8"/>
                                                <path d="M464.2,146.5c0-25.9-21-46.9-46.9-46.9c-14.1,0-26.7,6.2-35.3,16.1L289.3,62c1.6-4.7,2.5-9.8,2.5-15.1
                                                    c0-25.9-21-46.9-46.9-46.9S198,21,198,46.9c0,5.3,0.9,10.3,2.5,15l-92.7,53.7c-8.6-9.8-21.2-16.1-35.3-16.1
                                                    c-25.9,0-46.9,21-46.9,46.9c0,22.4,15.8,41.2,36.8,45.8l0.1,105.2c-21.1,4.6-37,23.4-37,45.8c0,25.9,21,46.9,46.9,46.9
                                                    c13.8,0,26.2-6,34.8-15.5l93,53.7c-1.5,4.6-2.3,9.4-2.3,14.5c0,25.9,21,46.9,46.9,46.9s46.9-21,46.9-46.9c0-5.1-0.8-9.9-2.3-14.5
                                                    l93-53.7c8.6,9.5,21,15.5,34.8,15.5c25.9,0,46.9-21,46.9-46.9c0-22.7-16.3-41.8-37.8-46V192.4
                                                    C447.9,188.3,464.2,169.3,464.2,146.5z M417.3,117.7c15.9,0,28.8,12.9,28.8,28.8s-12.9,28.8-28.8,28.8s-28.8-12.9-28.8-28.8
                                                    S401.5,117.7,417.3,117.7z M244.9,18.2c15.9,0,28.8,12.9,28.8,28.8c0,4.9-1.2,9.5-3.4,13.6c-0.2,0.2-0.3,0.4-0.5,0.7
                                                    c-0.1,0.2-0.2,0.4-0.3,0.6c-5,8.3-14.2,13.9-24.6,13.9c-15.9,0-28.8-12.9-28.8-28.8S229,18.2,244.9,18.2z M43.7,146.5
                                                    c0-15.9,12.9-28.8,28.8-28.8s28.8,12.9,28.8,28.8s-12.9,28.8-28.8,28.8S43.7,162.4,43.7,146.5z M72.5,372.1
                                                    c-15.9,0-28.8-12.9-28.8-28.8s12.9-28.8,28.8-28.8s28.8,12.9,28.8,28.8C101.2,359.2,88.3,372.1,72.5,372.1z M244.9,471.6
                                                    c-15.9,0-28.8-12.9-28.8-28.8S229,414,244.9,414s28.8,12.9,28.8,28.8S260.8,471.6,244.9,471.6z M280.6,412.5
                                                    c-8.6-10.1-21.4-16.6-35.7-16.6s-27.1,6.4-35.7,16.6l-92.6-53.4c1.8-4.9,2.8-10.3,2.8-15.8c0-23.1-16.7-42.3-38.7-46.2l-0.1-104.5
                                                    c22-3.8,38.8-23.1,38.8-46.2c0-5.3-0.9-10.4-2.5-15.1l92.7-53.7c8.6,9.9,21.3,16.1,35.4,16.1c14.1,0,26.7-6.2,35.3-16.1l92.7,53.7
                                                    c-1.6,4.7-2.5,9.8-2.5,15.1c0,22.7,16.3,41.8,37.8,46v104.8c-21.5,4.2-37.8,23.2-37.8,46c0,5.6,1,10.9,2.8,15.8L280.6,412.5z
                                                    M446.1,343.3c0,15.9-12.9,28.8-28.8,28.8s-28.8-12.9-28.8-28.8s12.9-28.8,28.8-28.8S446.1,327.4,446.1,343.3z"/>
                                                <path d="M288.6,281.1v-32.7c5.5-5.3,8.6-12.6,8.6-20.4v-42.9c0-26.3-21.4-47.7-47.7-47.7h-9.2c-26.3,0-47.7,21.4-47.7,47.7V228
                                                    c0,7.8,3.2,15.1,8.6,20.4v32.7c-10.4,5.4-35.4,19.1-58.8,38.3c-3.9,3.2-4.4,8.9-1.2,12.8s8.9,4.4,12.8,1.2
                                                    c25.9-21.3,53.9-35.4,59.3-38c3.8-1.9,6.1-5.6,6.1-9.9v-41.4c0-3-1.5-5.9-4-7.5c-2.9-1.9-4.6-5.1-4.6-8.6v-42.9
                                                    c0-16.3,13.3-29.6,29.6-29.6h9.2c16.3,0,29.6,13.3,29.6,29.6V228c0,3.4-1.7,6.6-4.6,8.6c-2.5,1.7-4,4.5-4,7.5v41.4
                                                    c0,4.2,2.4,8,6.2,9.9c5.4,2.6,33.4,16.8,59.3,38c1.7,1.4,3.7,2.1,5.7,2.1c2.6,0,5.2-1.1,7-3.3c3.2-3.9,2.6-9.6-1.2-12.8
                                                    C324,300.2,299,286.4,288.6,281.1z"/>
                                        </g></g>
                                    </svg>
                                </div>
                                <div>
                                <div class="text-muted text-uppercase font-weight-bold"> This Month </div>
                                <div class="text-value text-gradient-primary">{{ parseFloat(formData.this_month_user).toFixed(2) }}</div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </CCardBody>
        </CCard>
      </CCol>
    </CRow>
    <CRow>
      <CCol sm="12" md="12">
        <CCard accent-color="success">
          <CCardHeader><b> Click Summary</b></CCardHeader>
            <CCardBody>
                <div class="row">
                    <div class="col-12 col-sm-6 col-lg-3">
                        <div class="card">
                            <div class="card-body d-flex align-items-center p-0">
                                <div class="mr-3 text-white bg-gradient-primary p-4">
                                    <svg height="40px" width="40px" version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" viewBox="0 0 17.84 17.84" xml:space="preserve" fill="#ffffff" transform="matrix(1, 0, 0, 1, 0, 0)" stroke="#ffffff" stroke-width="0.00017837000000000002"><g id="SVGRepo_bgCarrier" stroke-width="0"></g><g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g><g id="SVGRepo_iconCarrier"> <g> <path style="fill:#ffffff;" d="M16.145,2.571c-0.272-0.273-0.718-0.273-0.99,0L6.92,10.804l-4.241-4.27 c-0.272-0.274-0.715-0.274-0.989,0L0.204,8.019c-0.272,0.271-0.272,0.717,0,0.99l6.217,6.258c0.272,0.271,0.715,0.271,0.99,0 L17.63,5.047c0.276-0.273,0.276-0.72,0-0.994L16.145,2.571z"></path> </g> </g></svg>                                
                                </div>
                                <div>
                                    <div class="text-muted text-uppercase font-weight-bold"> Today </div>
                                    <div class="text-value text-gradient-primary">{{ parseFloat(formData.today_click).toFixed(2) }}</div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-12 col-sm-6 col-lg-3">
                        <div class="card">
                            <div class="card-body d-flex align-items-center p-0">
                                <div class="mr-3 text-white bg-gradient-info p-4">
                                    <svg height="40px" width="40px" version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" viewBox="0 0 17.84 17.84" xml:space="preserve" fill="#ffffff" transform="matrix(1, 0, 0, 1, 0, 0)" stroke="#ffffff" stroke-width="0.00017837000000000002"><g id="SVGRepo_bgCarrier" stroke-width="0"></g><g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g><g id="SVGRepo_iconCarrier"> <g> <path style="fill:#ffffff;" d="M16.145,2.571c-0.272-0.273-0.718-0.273-0.99,0L6.92,10.804l-4.241-4.27 c-0.272-0.274-0.715-0.274-0.989,0L0.204,8.019c-0.272,0.271-0.272,0.717,0,0.99l6.217,6.258c0.272,0.271,0.715,0.271,0.99,0 L17.63,5.047c0.276-0.273,0.276-0.72,0-0.994L16.145,2.571z"></path> </g> </g></svg>                                
                                </div>
                                <div>
                                <div class="text-muted text-uppercase font-weight-bold"> PREV. Day </div>
                                <div class="text-value text-gradient-primary">{{ parseFloat(formData.previous_day_click).toFixed(2) }}</div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-12 col-sm-6 col-lg-3">
                        <div class="card">
                            <div class="card-body d-flex align-items-center p-0">
                                <div class="mr-3 text-white bg-gradient-warning p-4">
                                    <svg height="40px" width="40px" version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" viewBox="0 0 17.84 17.84" xml:space="preserve" fill="#ffffff" transform="matrix(1, 0, 0, 1, 0, 0)" stroke="#ffffff" stroke-width="0.00017837000000000002"><g id="SVGRepo_bgCarrier" stroke-width="0"></g><g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g><g id="SVGRepo_iconCarrier"> <g> <path style="fill:#ffffff;" d="M16.145,2.571c-0.272-0.273-0.718-0.273-0.99,0L6.92,10.804l-4.241-4.27 c-0.272-0.274-0.715-0.274-0.989,0L0.204,8.019c-0.272,0.271-0.272,0.717,0,0.99l6.217,6.258c0.272,0.271,0.715,0.271,0.99,0 L17.63,5.047c0.276-0.273,0.276-0.72,0-0.994L16.145,2.571z"></path> </g> </g></svg>                                

                                </div>
                                <div>
                                <div class="text-muted text-uppercase font-weight-bold"> Last 7 Day </div>
                                <div class="text-value text-gradient-primary">{{ parseFloat(formData.last_seven_day_click).toFixed(2) }}</div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="col-12 col-sm-6 col-lg-3">
                        <div class="card">
                            <div class="card-body d-flex align-items-center p-0">
                                <div class="mr-3 text-white bg-gradient-danger p-4">
                                                                        <svg height="40px" width="40px" version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" viewBox="0 0 17.84 17.84" xml:space="preserve" fill="#ffffff" transform="matrix(1, 0, 0, 1, 0, 0)" stroke="#ffffff" stroke-width="0.00017837000000000002"><g id="SVGRepo_bgCarrier" stroke-width="0"></g><g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round"></g><g id="SVGRepo_iconCarrier"> <g> <path style="fill:#ffffff;" d="M16.145,2.571c-0.272-0.273-0.718-0.273-0.99,0L6.92,10.804l-4.241-4.27 c-0.272-0.274-0.715-0.274-0.989,0L0.204,8.019c-0.272,0.271-0.272,0.717,0,0.99l6.217,6.258c0.272,0.271,0.715,0.271,0.99,0 L17.63,5.047c0.276-0.273,0.276-0.72,0-0.994L16.145,2.571z"></path> </g> </g></svg>                                

                                </div>
                                <div>
                                <div class="text-muted text-uppercase font-weight-bold"> This Month </div>
                                <div class="text-value text-gradient-primary">{{ parseFloat(formData.this_month_click).toFixed(2) }}</div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </CCardBody>
        </CCard>
      </CCol>
    </CRow>
    <CRow>
      <CCol sm="12" md="8">
        <topTen></topTen>
      </CCol>
      <CCol sm="12" md="4">
        <CCard accent-color="success">
          <CCardHeader><b> Payment Details</b></CCardHeader>
            <CCardBody>
                <div class="row">
                    <div class="col-12 col-sm-12 col-lg-12">
                        <table class="table table-sm table table-bordered">
                            <tr>
                                <td widtd="60%">Last 7 Day Paid</td>
                                <td class="text-right"> {{ parseFloat(formData.last_seven_day_paid).toFixed(2) }} tk</td>
                            </tr>
                            <tr>
                                <td>Last 7 Day Pending</td>
                                <td class="text-right"> {{ parseFloat(formData.last_seven_day_pending).toFixed(2) }} tk</td>
                            </tr>
                            <tr>
                                <td>This Month</td>
                                <td class="text-right"> {{ parseFloat(formData.this_month_paid).toFixed(2) }} tk</td>
                            </tr>
                            <tr>
                                <td>Total Paid</td>
                                <td class="text-right"> {{ parseFloat(formData.total_paid).toFixed(2) }} tk</td>
                            </tr>
                            <tr>
                                <td>Total Pending</td>
                                <td class="text-right"> {{ parseFloat(formData.total_pending).toFixed(2) }} tk</td>
                            </tr>
                        </table>
                    </div>
                </div>
            </CCardBody>
        </CCard>
      </CCol>
    </CRow>
  </div>
</template>

<script>
import topTen from '../components/dashboard/top-ten.vue'
import RestApi, { baseUrl } from '../config/api_config'
export default {
  name: 'Dashboard',
  components: {
    topTen
  },
  data () {
    return {
      selected: 'Month',
      formData: {}
    }
  },
  created() {
      this.loadData()
  },
  methods: {
        loadData () {
            const params = {}
            this.$store.dispatch('mutedLoad', { loading: true})
            RestApi.getData(baseUrl, 'api/dashboard/data', params).then(response => {
                if (response.success) {
                    this.formData = response.data
                }
                this.$store.dispatch('mutedLoad', { loading: false })
            })
        },
  }
}
</script>
